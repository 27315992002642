import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import AccommodationEditView from 'views/Property/RoomType/Accommodation/Edit/General';
import SettingsActions from 'store/reducers/Settings';
import AccommodationService from 'api/Accommodation';
import PropertyService from 'api/Property';
import RoomTypeService from 'api/RoomType';
import TagsPredefinedService from 'api/TagsPredefined';
import { formatDataToSend, handlePromise } from 'utils';

const AccommodationEdit = ( {
  data, entityId, paths, parentIds,
  toggleLoading, toggleInfoAlert, onReloadData,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const { mainImage, documentCheckInInstruction, instructionsType } = formData;
      const dataToSend = formatDataToSend( formData, form );
      const formattedData = { ...dataToSend };
      const propertyId = parentIds ? parentIds.property : formattedData.property;
      const roomTypeId = parentIds ? parentIds.roomType : formattedData.roomType;

      if ( !entityId ) formattedData.enabled = 'true';
      else formattedData.enabled = data.entity.enabled ? 'true' : 'false';
      formattedData.extraBedAllowed = formattedData.extraBedAllowed ? 'true' : 'false';
      formattedData.travelReportRequired = formattedData.travelReportRequired ? 'true' : 'false';
      formattedData.hostelry = formattedData.hostelry ? 'true' : 'false';
      if ( formattedData.propertyType ) {
        formattedData.type = formattedData.propertyType;
      }
      delete formattedData.propertyType;
      delete formattedData.documentCheckInInstruction;
      delete formattedData.instructionsType;
      // formattedData = formatCustomValuesToSend( formattedData, data.requiredCustomFields );

      const [errors, response] = await handlePromise(
        entityId
          ? AccommodationService.updateAccommodation(
            propertyId, roomTypeId, entityId, formattedData,
          )
          : AccommodationService.saveAccommodation(
            propertyId, roomTypeId, formattedData,
          ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      if ( documentCheckInInstruction
        && typeof documentCheckInInstruction === 'object'
      ) {
        if ( instructionsType === 'document' ) {
          await handlePromise(
            AccommodationService.uploadInstructionsFile(
              propertyId, roomTypeId, entityId || response.id, {
                document: documentCheckInInstruction,
              },
            ),
          );
        }
      }

      if ( typeof mainImage === 'object' ) {
        const [imageErrors, imageResponse] = await handlePromise(
          AccommodationService.uploadMainImage(
            propertyId, roomTypeId, entityId || response.id, {
              image: mainImage,
            },
          ),
        );
        if ( !imageResponse.ok ) {
          toggleLoading( false );
          return imageErrors;
        }
      }
      onReloadData();
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert, toggleLoading, data, entityId, parentIds, onReloadData,
    ],
  );

  /* const submitForm = useSubmit( {
    promise: ( dataToSend ) => {
      const propertyId = parentIds ? parentIds.property : dataToSend.property;
      const roomTypeId = parentIds ? parentIds.roomType : dataToSend.roomType;
      return ( entityId
        ? AccommodationService.updateAccommodation(
          propertyId, roomTypeId, entityId, dataToSend,
        )
        : AccommodationService.saveAccommodation(
          propertyId, roomTypeId, dataToSend,
        ) );
    },
    format: ( dataToSend ) => {
      const formattedData = { ...dataToSend };
      if ( !entityId ) formattedData.enabled = 'true';
      else formattedData.enabled = data.entity.enabled ? 'true' : 'false';
      formattedData.extraBedAllowed = dataToSend.extraBedAllowed ? 'true' : 'false';
      return formatCustomValuesToSend( formattedData, data.requiredCustomFields );
    },
    toggleLoading,
    toggleInfoAlert,
    deps: [data],
    callback: ( formData, responseData ) => ( entityId ? history.push( paths.accommodation )
      : history.push( `${paths.accommodation}/edit/${responseData.id}` ) ),
  } ); */

  const fetchProperties = useCallback( ( query ) => PropertyService.getProperties( {
    elementsPerPage: 10,
    keyword: query,
  } ), [] );

  const fetchRoomTypes = useCallback( ( propertyId, query ) => RoomTypeService.getRoomTypes(
    propertyId, {
      elementsPerPage: 10,
      keyword: query,
    },
  ), [] );

  const fetchTagsPredefined = useCallback( ( ) => TagsPredefinedService.getTagsPredefined(), [] );

  return (
    <AccommodationEditView
      data={data}
      parentIds={parentIds}
      entityId={entityId}
      paths={paths}
      onSubmit={submitForm}
      onFetchProperties={fetchProperties}
      onFetchRoomTypes={fetchRoomTypes}
      onFetchTagsPredefined={fetchTagsPredefined}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( AccommodationEdit );
