import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  reloadedAt: null,
  editModalOpened: false,
  taskId: null,
  taskToEditId: null,
  taskToEditData: null,
};

const { Types, Creators } = createActions( {
  reloadTask: ['id'],
  toggleEditModal: ['id', 'data'],
} );

const reloadTask = ( state, { id } ) => ( {
  ...state,
  taskId: id,
  reloadedAt: new Date().valueOf(),
} );

const toggleEditModal = ( state, { id, data } ) => ( {
  ...state,
  taskToEditId: id,
  taskToEditData: data,
  editModalOpened: !state.editModalOpened,
} );

export const reducer = createReducer( INITIAL_STATE, {
  [Types.RELOAD_TASK]: reloadTask,
  [Types.TOGGLE_EDIT_MODAL]: toggleEditModal,
} );

export const TaskTypes = Types;
export default Creators;
