// import _ from 'lodash';

import General from 'containers/Places/Edit/General';
import AmenityInventory from 'containers/Places/Edit/AmenityInventory/Dashboard';

export default [
  {
    name: 'general',
    path: '',
    component: General,
    exact: true,
  },
  {
    name: 'inventory',
    path: '/inventory',
    component: AmenityInventory,
  },
];
