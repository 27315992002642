/* eslint-disable import/no-named-as-default-member */
import React, { useCallback, useEffect, useRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Spinner } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import numeral from 'numeral';
import _ from 'lodash';
import moment from 'moment';
import Pusher from 'pusher-js';
import 'moment-timezone';
import 'moment/locale/es';

import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'react-image-lightbox/style.css';
import 'rc-drawer/assets/index.css';
import 'assets/scss/argon-dashboard-pro-react.scss?v1.0.0';

import 'config/Numeral';
import PrivateLayout from 'layouts/Private';
import AuthLayout from 'layouts/Auth';
import AppLocale from 'i18n';
import { Intl, ErrorBoundary } from 'components';
import { currencyLocale, handlePromise, storage } from 'utils';
import classnames from 'classnames';
import UserActions from 'store/reducers/User';
import AppVersionService from 'api/AppVersion';
import MessengerActions from './store/reducers/Messenger';
import TaskActions from './store/reducers/Task';
import IncidenceActions from './store/reducers/Incidence';
import SettingsActions from './store/reducers/Settings';
import ability from './config/Ability';

const {
  REACT_APP_PUSHER_KEY,
  REACT_APP_PUSHER_CLUSTER,
  REACT_APP_PUSHER_FORCE_TLS,
  REACT_APP_PUSHER_UPDATE_CH,
  REACT_APP_PUSHER_UPDATE_EVENT,
  REACT_APP_PUSHER_UPDATE_MESSAGE,
} = process.env;

const socket = new Pusher( REACT_APP_PUSHER_KEY, {
  cluster: REACT_APP_PUSHER_CLUSTER,
  forceTLS: REACT_APP_PUSHER_FORCE_TLS,
} );
Pusher.logToConsole = true;

const testChannel = socket.subscribe( REACT_APP_PUSHER_UPDATE_CH );
testChannel.bind( REACT_APP_PUSHER_UPDATE_EVENT, ( data ) => {
  if ( data === REACT_APP_PUSHER_UPDATE_MESSAGE ) {
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  }
} );

const PrivateRoute = ( { component: Component, ...rest } ) => (
  <Route
    {...rest}
    render={( props ) => (
      rest.user && rest.user.accessToken
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
    )}
  />
);

const PublicRoute = ( { component: Component, ...rest } ) => (
  <Route
    {...rest}
    render={( props ) => (
      !rest.user || !rest.user.accessToken
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )}
  />
);

const App = ( {
  user, locale, alert, taskToEditId, selectedChannelId, taskEditModalOpened,
  updateNewMessage, reloadTask, toggleTaskEditModal, location,
  setReloadNotificationsFlag, appVersion, logoutUser, resetSettings,
  incidenceToEditId, incidenceEditModalOpened, reloadIncidence, toggleIncidenceEditModal,
} ) => {
  if ( _.get( user, 'settings.currency' ) ) {
    numeral.locale( currencyLocale( user.settings.currency ) );
  }
  moment.locale( locale.locale );

  const resetData = useCallback( () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    logoutUser();
    resetSettings();
  }, [logoutUser, resetSettings] );

  useEffect( () => {
    if ( !appVersion ) {
      resetData();
    }
  }, [appVersion, resetData] );
  // moment.tz.setDefault( 'UTC' );

  useEffect( () => {
    const checkServerVersion = async () => {
      // eslint-disable-next-line no-unused-vars
      const [versionErrors, versionResponse, versionData] = await handlePromise(
        AppVersionService.getAppVersions(),
      );

      if ( !versionResponse.ok ) {
        storage.clear( 'nomads:token' );
        return 'apiError';
      }

      if ( versionData.Manager ) {
        const serverVersion = parseFloat( versionData.Manager.replace( 'v', '' ) );
        if ( appVersion > 0 ) {
          if ( serverVersion > appVersion ) {
            resetData();
          }
        }
      }
    };
    checkServerVersion();
  }, [appVersion, resetData] );

  const messages = AppLocale[locale.locale];
  const notificationAlert = useRef( null );
  const isInitialMount = useRef( true );
  const userId = _.get( user, 'user.id' );
  const userIdRef = useRef( _.get( user, 'user.id' ) );
  const selectedChannelIdRef = useRef( selectedChannelId );
  const locationPathnameRef = useRef( location.pathname );
  const taskToEditIdRef = useRef( taskToEditId );
  const taskEditModalOpenedRef = useRef( taskEditModalOpened );
  const incidenceToEditIdRef = useRef( incidenceToEditId );
  const incidenceEditModalOpenedRef = useRef( incidenceEditModalOpened );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else {
      const options = {
        place: 'tr',
        message: (
          <div className="alert-text" data-cy={`alert-${alert.alertType}`}>
            <span data-notify="message">
              <Intl id={alert.msg} />
            </span>
          </div>
        ),
        type: alert.alertType || 'danger',
        icon: 'app-icon-warning',
        autoDismiss: 10,
      };
      if ( alert ) notificationAlert.current.notificationAlert( options );
    }
  }, [alert] );

  useEffect( () => {
    selectedChannelIdRef.current = selectedChannelId;
  }, [selectedChannelId] );

  useEffect( () => {
    locationPathnameRef.current = location.pathname;
  }, [location.pathname] );

  useEffect( () => {
    taskEditModalOpenedRef.current = taskEditModalOpened;
  }, [taskEditModalOpened] );

  useEffect( () => {
    taskToEditIdRef.current = taskToEditId;
  }, [taskToEditId] );

  useEffect( () => {
    incidenceEditModalOpenedRef.current = incidenceEditModalOpened;
  }, [incidenceEditModalOpened] );

  useEffect( () => {
    incidenceToEditIdRef.current = incidenceToEditId;
  }, [incidenceToEditId] );

  const getNotificationTaskOptions = useCallback(
    ( userLoggedInId, data, hasPermissions, icon = 'ni ni-bell-55' ) => {
      if ( data.sender.id !== userLoggedInId && hasPermissions ) {
        if ( data.extra.isIncidence ) {
          reloadIncidence( data.extra.id );
          if ( data.extra.id !== taskToEditIdRef.current ) {
            notificationAlert.current.notificationAlert( {
              place: 'tr',
              message: (
                <div className="alert-text cursor-pointer">
                  <span
                    data-notify="message"
                    onClick={() => {
                      if ( incidenceToEditIdRef.current !== data.extra.id ) {
                        if ( incidenceEditModalOpenedRef.current ) {
                          toggleIncidenceEditModal( null );
                        }
                        toggleIncidenceEditModal( data.extra.id );
                      }
                    }}
                  >
                    {data.body}
                  </span>
                </div>
              ),
              type: 'outline-primary',
              icon,
              autoDismiss: 10,
            } );
          }
          return;
        }
        reloadTask( data.extra.id );

        if ( data.extra.id !== taskToEditIdRef.current ) {
          notificationAlert.current.notificationAlert( {
            place: 'tr',
            message: (
              <div className="alert-text cursor-pointer">
                <span
                  data-notify="message"
                  onClick={() => {
                    if ( taskToEditIdRef.current !== data.extra.id ) {
                      if ( taskEditModalOpenedRef.current ) {
                        toggleTaskEditModal( null );
                      }
                      toggleTaskEditModal( data.extra.id );
                    }
                  }}
                >
                  {data.body}
                </span>
              </div>
            ),
            type: 'outline-primary',
            icon,
            autoDismiss: 10,
          } );
        }
      }
    },
    [reloadTask, toggleTaskEditModal, reloadIncidence, toggleIncidenceEditModal],
  );

  const getNotificationMessageOptions = useCallback(
    ( title, msg, icon = 'ni ni-bell-55' ) => ( {
      place: 'tr',
      message: (
        <div className="alert-text">
          <p className="m-0">
            <Intl id={title} />
          </p>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: 'outline-primary',
      icon,
      autoDismiss: 10,
    } ),
    [],
  );

  const onReloadNotificationsFlag = useCallback( () => {
    setReloadNotificationsFlag( new Date().valueOf() );
  }, [setReloadNotificationsFlag] );

  useEffect( () => {
    const subscribe = () => {
      const channel = socket.subscribe( userId );

      channel.bind( 'messenger_message', ( data ) => {
        onReloadNotificationsFlag();
        if ( locationPathnameRef.current === '/support' ) {
          updateNewMessage( data );
        } else {
          updateNewMessage( null );
        }
        if ( locationPathnameRef.current !== '/support' || data.extra.id !== selectedChannelIdRef.current ) {
          notificationAlert.current.notificationAlert(
            getNotificationMessageOptions(
              'newMessage',
              data.body,
              'app-icon-support',
            ),
          );
        }
      } );
      channel.bind( 'messenger_channel_new', ( data ) => {
        onReloadNotificationsFlag();
        notificationAlert.current.notificationAlert(
          getNotificationMessageOptions(
            'newChannel',
            data.body,
            'app-icon-support',
          ),
        );
      } );
      channel.bind( 'task_assignation', ( data ) => {
        onReloadNotificationsFlag();
        getNotificationTaskOptions(
          userId,
          data,
          ability.can( 'lists', 'task' ) || ability.can( 'view', 'task' ),
          'fas fa-id-badge',
        );
      } );
      channel.bind( 'task_status', ( data ) => {
        onReloadNotificationsFlag();
        getNotificationTaskOptions(
          userId,
          data,
          ability.can( 'lists', 'task' ) || ability.can( 'view', 'task' ),
          'app-icon-task',
        );
      } );
      channel.bind( 'task_priority', ( data ) => {
        onReloadNotificationsFlag();
        getNotificationTaskOptions(
          userId,
          data,
          ( ability.can( 'lists', 'task' ) || ability.can( 'view', 'task' ) ),
          'fas fa-tasks',
        );
      } );
      channel.bind( 'task_note', ( data ) => {
        onReloadNotificationsFlag();
        getNotificationTaskOptions(
          userId,
          data,
          ability.can( 'view', 'task' ),
          'fas fa-comments',
        );
      } );
      channel.bind( 'task_image', ( data ) => {
        onReloadNotificationsFlag();
        getNotificationTaskOptions(
          userId,
          data,
          ability.can( 'view', 'task' ),
          'fas fa-images',
        );
      } );
    };
    const unsubscribe = () => {
      socket.unsubscribe( userIdRef.current );
    };
    if ( userId ) subscribe();
    else if ( userIdRef.current ) unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId] );

  return (
    <ErrorBoundary>
      <IntlProvider
        locale={locale.locale}
        messages={messages}
      >
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlert} />
        </div>

        <Switch>
          <PublicRoute path="/auth" component={AuthLayout} user={user} />
          <PrivateRoute path="/" component={PrivateLayout} user={user} />
          <Redirect from="*" to="/" />
        </Switch>

        <ConectedLoading />
      </IntlProvider>
    </ErrorBoundary>
  );
};

const Loading = ( { isLoading } ) => (
  <div
    className={classnames( 'app-loading ', {
      invisible: !isLoading,
      visible: isLoading,
    } )}
  >
    <Spinner type="grow" color="primary" style={{ width: '3rem', height: '3rem' }} />
  </div>
);

const mapStateToPropsLoading = ( { settings } ) => ( { isLoading: settings.isLoading } );
const ConectedLoading = connect( mapStateToPropsLoading )( Loading );

const mapStateToProps = ( {
  settings, user, task, messenger, incidence,
} ) => {
  const { locale, alert, appVersion } = settings;
  return {
    locale,
    alert,
    user,
    appVersion,
    selectedChannelId: messenger.selectedChannelId,
    taskToEditId: task.taskToEditId,
    taskEditModalOpened: task.editModalOpened,
    incidenceToEditId: incidence.incidenceToEditId,
    incidenceEditModalOpened: incidence.editIncidenceModalOpened,
  };
};

const mapDispatchToProps = ( {
  logoutUser: UserActions.logoutUser,
  updateNewMessage: MessengerActions.updateNewMessage,
  reloadTask: TaskActions.reloadTask,
  reloadIncidence: IncidenceActions.reloadIncidence,
  toggleTaskEditModal: TaskActions.toggleEditModal,
  toggleIncidenceEditModal: IncidenceActions.toggleIncidenceEditModal,
  setReloadNotificationsFlag: SettingsActions.setReloadNotificationsFlag,
  resetSettings: SettingsActions.resetSettings,
} );

export default connect( mapStateToProps, mapDispatchToProps )( App );
