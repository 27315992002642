/* eslint-disable no-nested-ternary */
import React from 'react';
import { Col, Row } from 'reactstrap';
import _ from 'lodash';

import {
  Form,
  TextField,
  Select,
  FormActionsBtns,
  EditCard,
  Switch,
  Intl,
  CanAccess,
  AsyncSelect,
} from 'components';
import { required } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import { cleaningStatus } from 'config/Constants';
import propertyTypes from 'config/Constants/propertyTypes';
import { initializeType, initializeDocument } from './utils';

export default React.memo(
  ( {
    data,
    entityId,
    parentIds,
    paths,
    onSubmit,
    onFetchProperties,
    onFetchRoomTypes,
    onFetchTagsPredefined,
  } ) => (
    <EditCard
      headerContent={(
        <h3 className="mb-0 mt-1">
          <Intl
            id={
              entityId
                ? canModify( 'accommodation' )
                  ? 'editAccommodation'
                  : 'accommodation'
                : 'newAccommodation'
            }
          />
          {' '}
          {entityId ? _.get( data, 'entity.name' ) : null}
        </h3>
      )}
      cardClass="card-sec-bottom"
    >
      <Form
        initialValues={{
          ...data.entity,
          propertyType: data?.entity?.type || '',
          tags: ( data?.entity?.tags || [] ).map( ( item ) => ( {
            value: item,
            label: item,
          } ) ),
          instructionsType: initializeType( data?.entity ),
          documentCheckInInstruction: initializeDocument( data?.entity ),
          // tags: [{ id: 'ribera', name: 'ribera' }],
        }}
        onSubmit={canModify( 'accommodation' ) ? onSubmit : () => {}}
        showErrorsInFooter
        body={( form ) => {
          const property = _.get( form.getState().values, 'property.value' );
          const roomType = _.get( form.getState().values, 'roomType.value' );
          const instructionType = _.get(
            form.getState().values,
            'instructionsType',
          );
          return (
            <>
              {!entityId && !parentIds && (
                <Row>
                  <Col md={4}>
                    <AsyncSelect
                      field="property"
                      label="property"
                      defaultOptions
                      loadOptions={onFetchProperties}
                      validate={required}
                      onChange={() => {
                        if ( roomType ) form.change( 'roomType', null );
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <AsyncSelect
                      field="roomType"
                      label="roomType"
                      defaultOptions
                      key={property}
                      loadOptions={
                        ( query ) => ( property ? onFetchRoomTypes( property, query ) : {} )
                      }
                      validate={required}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={4}>
                  <TextField field="name" label="name" validate={required} />
                </Col>
                <Col md={4}>
                  <AsyncSelect
                    field="tags"
                    label="tags"
                    defaultOptions
                    loadOptions={onFetchTagsPredefined}
                    multiple
                    formatFn={
                      ( dataTagsPredefined ) => ( dataTagsPredefined || [] ).map( ( item ) => ( {
                        id: item,
                        name: item,
                      } ) )
                  }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <TextField field="block" label="accommodation.block" />
                </Col>
                <Col md={2}>
                  <TextField field="floor" label="floor" />
                </Col>
                <Col md={2}>
                  <TextField field="number" label="number" />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <TextField
                    field="minimumCapacity"
                    label="minimumCapacity"
                    type="number"
                    validate={required}
                  />
                </Col>
                <Col md={2}>
                  <TextField
                    field="maximumCapacity"
                    label="maximumCapacity"
                    type="number"
                    validate={required}
                  />
                </Col>
                <Col md={2}>
                  <TextField
                    field="rooms"
                    label="rooms"
                    type="number"
                    validate={required}
                  />
                </Col>
                <Col md={2}>
                  <TextField
                    field="bathrooms"
                    label="bathrooms"
                    type="number"
                    validate={required}
                  />
                </Col>
                <Col md={2}>
                  <Switch field="extraBedAllowed" label="extraBedAllowed" />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <TextField field="externalId" label="externalId" readOnly />
                </Col>
                <Col md={2}>
                  <Switch
                    field="travelReportRequired"
                    label="travelReportRequired"
                  />
                </Col>
                <Col md={2}>
                  <Select
                    field="cleaningStatus"
                    label="cleaningStatus"
                    validate={required}
                    translateOptions
                    options={cleaningStatus}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={2}>
                  <Switch field="hostelry" label="isHostelry" />
                </Col>
                <Col md={4}>
                  <Select
                    field="propertyType"
                    label="propertyType"
                    //  validate={required}
                    translateOptions
                    options={propertyTypes}
                  />
                </Col>
              </Row>
              <Row>
                {data.entity && data.entity.mainImage && (
                  <Col md={3}>
                    <img
                      src={data.entity.mainImage}
                      width="100%"
                      alt="mainImage"
                    />
                  </Col>
                )}
                <Col md={4}>
                  <TextField
                    label="apartmentPicture"
                    type="file"
                    onChange={async ( val, e ) => {
                      const mainImage = e.currentTarget.files[0];
                      if ( mainImage ) {
                        form.change( 'mainImage', mainImage );
                      }
                    }}
                    labelClass="custom-file-label"
                    inputClass="custom-file-input"
                    formGroupClass="custom-file"
                    accept="image/x-png,image/jpeg"
                  />
                  <span className="mt-1 help-text">
                    <Intl id="accommodationImage.help" />
                  </span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={3}>
                  <Select
                    field="instructionsType"
                    label="instructionsType"
                    onChange={( value ) => {
                      form.change( 'documentCheckInInstruction', null );
                      form.change( 'instructionsType', value );
                    }}
                    options={[
                      {
                        id: 'text',
                        name: 'text',
                      },
                      {
                        id: 'document',
                        name: 'document',
                      },
                    ]}
                    translateOptions
                  />
                </Col>

                {instructionType === 'document' ? (
                  <Col className="media-comment" md={4}>
                    <TextField
                      label={_.get( form.getState( ), 'values.documentCheckInInstruction.name', 'documentCheckInInstruction' )}
                      type="file"
                      onChange={async ( val, e ) => {
                        const mainFile = e.currentTarget.files[0];
                        if ( mainFile ) {
                          form.change( 'documentCheckInInstruction', mainFile );
                        }
                      }}
                      // field="documentCheckInInstruction"
                      labelClass="custom-file-label"
                      inputClass="custom-file-input"
                      formGroupClass="custom-file"
                      accept="application/pdf"
                    />
                    <span className="mt-1 help-text">
                      <Intl id="accommodationImage.help" />
                    </span>
                  </Col>
                ) : (
                  <Col md={4}>
                    <TextField
                      field="checkInInstructions"
                      label="checkInInstructions"
                      type="textarea"
                      rows={5}
                    />
                  </Col>
                )}

              </Row>

              {/* {data.requiredCustomFields && data.requiredCustomFields.length
              ? (
                <>
                  <hr className="divider-dashed" />
                  <h4 className="font-weight-normal text-uppercase"><Intl id="customFields" /></h4>

                  <Row>
                    {_.map( data.requiredCustomFields, ( item ) => (
                      <Col md={5} key={item.id}>
                        <CustomField field={item} />
                      </Col>
                    ) )}
                  </Row>
                </>
              ) : null} */}
            </>
          );
        }}
        footer={(
          <CanAccess I="edit" a="accommodation">
            <FormActionsBtns cancelTo={paths.accommodation} />
          </CanAccess>
        )}
      />
    </EditCard>
  ),
);
